import { OrderRefundsState, OrderRefundsStatus } from './types'

const initialState: OrderRefundsState = Object.freeze({
  orderRefunds: {
    data: [],
    status: OrderRefundsStatus.pending,
  },
  orderCreditRefunds: {
    data: [],
    status: OrderRefundsStatus.pending,
  },
})

export default initialState
