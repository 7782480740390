import styled, { css } from 'styled-components'
export const Wrap = styled.div`
  align-items: center;
  display: flex;
  width: fit-content;
  margin: 0 auto;
`

export const Title = styled.div`
  margin-right: 50px;
  font-size: 18px;
  font-weight: bold;
`
export const Element = styled.div`
  border: 1px solid ${(props) => props.theme.color.lightGray};
  border-radius: 12px;
  cursor: pointer;
  display: inline-block;
  height: 24px;
  transition: border-color 0.1s ease;
  width: 48px;

  > div {
    background-color: ${(props) => props.theme.color.lightGray};
    border-radius: 18px;
    height: 18px;
    margin-left: 3px;
    margin-top: 2px;
    transform: translateX(0);
    transition:
      transform 0.2s ease,
      background-color 0.2s ease;
    width: 18px;
  }

  ${(props) =>
    props.on &&
    css`
      border: 1px solid ${(props) => props.theme.color.green};

      > div {
        background-color: ${(props) => props.theme.color.green};
        transform: translateX(125%);
      }
    `}
`
export const Text = styled.div`
  color: ${(props) => props.theme.color.dark};
  display: inline-block;
  font-size: ${(props) => props.textFontSize}px;
  font-weight: 400;
  letter-spacing: 0;
  margin-left: 10px;
  text-transform: none;
`
