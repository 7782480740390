import { pure } from 'recompose'
import LightBox from '~/app/components/LightBox'
import moment from 'moment'
import Switch from '~/app/components/InputTypes/Switch'
import { useEffect, useMemo, useState } from 'react'
import { option, switchOn } from '~/app/utils/switchOn'
import { OrderRefundsStatus, RootState } from '~/app/store/user/refunds/types'
import { useMappedState } from '~/app/hooks/useReduxStore'
import CreditCardIcon from '~/app/assets/svg/CreditCardIcon'
import GiftIcon from '~/app/assets/svg/GiftIcon'
import user$ from '~/app/store/user'
import Loader from '~/app/components/Loader'
import Modal from '~/app/components/Modal'

import { Order } from '../types'
import OrderIssueForm from './OrderIssue/OrderIssueForm'
import PreviousOrderIssuesTable from './OrderIssue/PreviousOrderIssuesTable'
import RefundOrderForm from './Refund/RefundForm'
import PreviousRefundsTable from './Refund/PreviousRefundsTable'
import CreditOrderForm from './Credit/CreditRefundForm'
import {
  Wrapper,
  Info,
  Content,
  LoaderWrapper,
  DialogHeader,
  SwitchWrapper,
  SwitchTitle,
  ButtonsWrapper,
  IconButton,
  MainTitleText,
  Heading,
} from './styled'
import PreviousCreditRefundsTable from './Credit/PreviousCreditRefundsTable'

interface FaultyOrderReason {
  label: string
  value: number
  isDeprecated: boolean
}

interface Props {
  faultyOrderReasons: FaultyOrderReason[]
  isOpen: boolean
  onCancel: () => void
  onConfirm: () => void
  giftProducts: any[]
  faultyOrder: Order
  userId: number
  setShowOrderIncidentModal: (value: boolean) => void
  orderTotal: number
}

function ReportIssueAndRefundDialog(props: Props) {
  const self = useController(props)

  return (
    <>
      <LightBox isOpen={props.isOpen} closeHandler={self.handleClose}>
        <Wrapper>
          <Content>
            {(props.faultyOrder.deliveryIssues?.length ?? 0) > 0 && (
              <PreviousOrderIssuesTable
                data={props.faultyOrder.deliveryIssues!}
                giftProducts={props.giftProducts}
                faultyOrderReasons={self.faultyOrderReasons}
              />
            )}
            <DialogHeader>
              <MainTitleText>Report issue</MainTitleText>
              <Info>
                If there was an issue with the order, set the details and resend if necessary
              </Info>
            </DialogHeader>
            <OrderIssueForm
              onSubmit={props.onConfirm}
              isFaulty={false}
              issueIds={[]}
              resend={false}
              note=""
              photosProvided={false}
              faultyOrderReasons={self.faultyOrderReasons}
              giftProducts={props.giftProducts}
              extraProduct={false}
              order={props.faultyOrder}
              products={[]}
              setFormHasChanges={(value: boolean) => self.setOrderIssueFormHasChanges(value)}
            />
            <SwitchWrapper>
              <SwitchTitle>Compensation?</SwitchTitle>
              <Switch
                value={self.isCompensationEnabled}
                onChange={() => self.setIsCompensationEnabled(!self.isCompensationEnabled)}
                textIfFalse="No"
                textIfTrue="Yes"
              />
            </SwitchWrapper>
            {self.isCompensationEnabled && (
              <>
                {switchOn(
                  option(
                    self.isLoading && (
                      <LoaderWrapper>
                        <Loader />
                      </LoaderWrapper>
                    ),
                  ),
                  option(
                    (self.orderRefunds.length > 0 || self.orderCreditRefunds.length > 0) && (
                      <>
                        <DialogHeader>
                          <Heading noTopMargin>PREVIOUS COMPENSATION FOR THIS CHARGE</Heading>
                          <span>
                            Note: It shows all refunds associated with this charge, not this order,
                            so you might see refunds from different order here.
                          </span>
                        </DialogHeader>
                        <PreviousRefundsTable
                          orderRefunds={self.orderRefunds}
                          productsByIdOrSku={self.productsByIdOrSku}
                        />
                        <PreviousCreditRefundsTable orderCreditRefunds={self.orderCreditRefunds} />
                      </>
                    ),
                  ),
                )}
                {self.showRefundForm && !self.isLoading && (
                  <>
                    <DialogHeader>
                      <SwitchTitle>Compensation Type</SwitchTitle>
                    </DialogHeader>
                    <ButtonsWrapper>
                      <IconButton
                        onClick={() => self.setActiveTab('refund')}
                        isActive={self.activeTab === 'refund'}
                      >
                        <CreditCardIcon />
                        Refund
                      </IconButton>
                      <IconButton
                        onClick={() => self.setActiveTab('credit')}
                        isActive={self.activeTab === 'credit'}
                      >
                        <GiftIcon />
                        Credit
                      </IconButton>
                    </ButtonsWrapper>
                    {switchOn(
                      option(
                        self.activeTab === 'refund' && (
                          <RefundOrderForm
                            order={props.faultyOrder}
                            onSubmit={self.handleCreateOrderRefund}
                            isLoading={self.isLoading}
                            setFormHasChanges={self.setRefundFormHasChanges}
                            orderTotal={props.orderTotal}
                          />
                        ),
                      ),
                      option(
                        self.activeTab === 'credit' && (
                          <CreditOrderForm
                            orderTotal={props.orderTotal}
                            handleConfirm={self.handleRefundInCredits}
                            isLoading={self.isLoadingCreditsRefund}
                            order={props.faultyOrder}
                          />
                        ),
                      ),
                    )}
                  </>
                )}
              </>
            )}
          </Content>
        </Wrapper>
      </LightBox>
      <Modal
        isOpen={self.isConfirmationModalOpen}
        mainBtn="Continue Editing"
        onMainBtnClick={() => self.setIsConfirmationModalOpen(false)}
        secondaryBtn="Exit and Discard Changes"
        onSecondaryBtnClick={() => props.setShowOrderIncidentModal(false)}
        title="You have unsaved changes. Are you sure you want to exit?"
      />
    </>
  )
}

function useController(props: Props) {
  const refundForm = useRefundForm(props)
  const faultyOrderReasons = useMemo(
    () => getFaultyOrderReasons(props.faultyOrderReasons),
    [props.faultyOrderReasons],
  )
  const [isCompensationEnabled, setIsCompensationEnabled] = useState(
    refundForm.orderRefunds.length > 0,
  )
  const [activeTab, setActiveTab] = useState<'refund' | 'credit'>('refund')
  const [orderIssueFormHasChanges, setOrderIssueFormHasChanges] = useState(false)
  const [refundFormHasChanges, setRefundFormHasChanges] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  const handleClose = () => {
    if (!orderIssueFormHasChanges && !refundFormHasChanges) {
      props.setShowOrderIncidentModal(false)
    } else {
      setIsConfirmationModalOpen(true)
    }
  }

  const refundCreditsForm = useRefundCreditsForm(props)

  return {
    ...refundForm,
    ...refundCreditsForm,
    faultyOrderReasons,
    isCompensationEnabled,
    setIsCompensationEnabled,
    activeTab,
    setActiveTab,
    handleClose,
    setOrderIssueFormHasChanges,
    setRefundFormHasChanges,
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
  }
}

function getFaultyOrderReasons(faultyOrderReasons: FaultyOrderReason[]) {
  return faultyOrderReasons
    .filter(({ isDeprecated }) => !isDeprecated)
    .sort((reasonA, reasonB) =>
      reasonA.label.localeCompare(reasonB.label, 'en', {
        ignorePunctuation: true,
      }),
    )
    .reduce(
      (acc, reason) => {
        const splitLabel = reason.label.split(' - ')
        const existingGroup = acc.find((group) => group.name === splitLabel[0])
        if (existingGroup) {
          existingGroup.options.push({ label: splitLabel[1], value: reason.value })
          return acc
        }
        acc.push({
          name: splitLabel[0],
          options: [{ label: splitLabel[1] ?? reason.label, value: reason.value }],
        })
        return acc
      },
      [] as {
        name: string
        options: { label: string; value: number }[]
      }[],
    )
}

function useRefundForm(props: Props) {
  const refundsState = useMappedState((state: object & { user: RootState }) => state.user.refunds)
  const orderRefundsState = refundsState.orderRefunds
  const orderCreditRefundsState = refundsState.orderCreditRefunds
  const isLoading =
    orderRefundsState.status !== OrderRefundsStatus.loaded ||
    orderCreditRefundsState.status !== OrderRefundsStatus.loaded
  const { productsByIdOrSku } = useMappedState((state) => ({
    productsByIdOrSku: state.products.dataByIdOrSku || {},
  }))
  const showRefundForm = moment(props.faultyOrder.editableTo).isAfter(
    moment().subtract(3, 'months'),
  )

  useEffect(() => {
    user$.call.fetchOrderRefunds(props.faultyOrder.id)
    user$.call.fetchChargeCreditRefunds({
      userId: props.userId,
      chargeId: props.faultyOrder.charge.id,
    })
  }, [props.faultyOrder.id])

  const handleClose = () => {
    user$.call.setOrderRefundsInitialState()
    props.onCancel()
  }

  const handleCreateOrderRefund = (payload: {
    products: { id: number; count: number }[]
    customRefundReason?: string
    customRefundAmount?: number
  }) => {
    user$.call.createOrderRefund({
      products: payload.products,
      orderId: props.faultyOrder.id,
      chargeId: props.faultyOrder.charge.id,
      customRefundReason: payload.customRefundReason,
      customRefundAmount: payload.customRefundAmount,
      userId: props.userId,
    })
  }

  return {
    orderRefunds: orderRefundsState.data,
    orderCreditRefunds: orderCreditRefundsState.data,
    handleClose,
    handleCreateOrderRefund,
    productsByIdOrSku,
    isLoading,
    showRefundForm,
  }
}

function useRefundCreditsForm(props: Props) {
  const [isLoadingCreditsRefund, setIsLoadingCreditsRefund] = useState(false)

  const handleRefundInCredits = async (payload: {
    amount: number
    note: string
    notify: boolean
    mainReasonId: number
    subReasonId: number
    authorId: number
  }) => {
    setIsLoadingCreditsRefund(true)

    await user$.call.postUserCredits({
      data: { ...payload, incidentChargeId: props.faultyOrder.charge.id },
      userId: props.userId,
    })
    await user$.call.fetchChargeCreditRefunds({
      userId: props.userId,
      chargeId: props.faultyOrder.charge.id,
    })

    setIsLoadingCreditsRefund(false)
  }

  return {
    handleRefundInCredits,
    isLoadingCreditsRefund,
  }
}

export default pure(ReportIssueAndRefundDialog)
