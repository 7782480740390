import { isNumber, required, maxLength } from '~/app/common/validators'
import Currency from '~/app/components/InputTypes/Currency'
import InputTextArea from '~/app/components/InputTypes/Textarea'
import Switch from '~/app/components/InputTypes/Switch'
import Select from '~/app/components/InputTypes/Select'

const formDefinition = [
  {
    label: 'Amount',
    name: 'amount',
    type: Currency,
    validators: [isNumber, required],
    labelMargins: '0 0 0.5rem 0',
  },
  {
    label: 'Note',
    name: 'note',
    type: InputTextArea,
    validators: [maxLength(255), required],
    labelMargins: '0 0 0.5rem 0',
  },
  {
    label: 'Notify User?',
    name: 'notify',
    textIfFalse: 'No',
    textIfTrue: 'Yes',
    type: Switch,
    value: false,
    labelMargins: '0 0 0.5rem 0',
  },
  {
    hasEmptyOption: true,
    label: 'Reason for Credit *',
    name: 'mainCreditsReason',
    type: Select,
    validators: [required],
    labelMargins: '0 0 0.5rem 0',
  },
  {
    hasEmptyOption: true,
    label: 'Sub Reason *',
    name: 'subCreditsReason',
    type: Select,
    validators: [required],
    labelMargins: '0 0 0.5rem 0',
  },
  {
    label: 'Is custom credit?',
    name: 'isCustomCredit',
    type: Switch,
  },
]
export default formDefinition
