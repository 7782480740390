import { OrderRefundsStatus, RootState } from './types'
import initialState from './initialState'
import { ApiOrderCreditRefund, ApiOrderRefund, ApiOrderRefundSummary } from '~/app/common/api/types'

export function setOrderRefundsLoading(state: RootState) {
  return () =>
    ({
      ...state,
      refunds: {
        ...state.refunds,
        orderRefunds: {
          ...state.refunds.orderRefunds,
          status: OrderRefundsStatus.loading,
        },
      },
    }) as RootState
}

export function setOrderRefundsLoaded(state: RootState) {
  return ({ data }: { data: ApiOrderRefund[] }) =>
    ({
      ...state,
      refunds: {
        ...state.refunds,
        orderRefunds: {
          ...state.refunds.orderRefunds,
          data: structuredClone(data),
          status: OrderRefundsStatus.loaded,
        },
      },
    }) as RootState
}

export function setOrderRefundsInitialState(state: RootState) {
  return () =>
    ({
      ...state,
      refunds: initialState,
    }) as RootState
}

export function setOrderRefundsSummaryLoaded(state: RootState) {
  return ({ data }: { data: ApiOrderRefundSummary }) =>
    ({
      ...state,
      refunds: {
        ...state.refunds,
        orderRefunds: {
          ...state.refunds.orderRefunds,
          summary: data,
        },
      },
    }) as RootState
}

export function setOrderRefundsSummaryInitialState(state: RootState) {
  return () =>
    ({
      ...state,
      refunds: {
        ...state.refunds,
        orderRefunds: {
          ...state.refunds.orderRefunds,
          summary: { amount: 0 },
        },
      },
    }) as RootState
}

export function setOrderRefundsError(state: RootState) {
  return () =>
    ({
      ...state,
      refunds: {
        ...state.refunds,
        orderRefunds: {
          ...state.refunds.orderRefunds,
          status: OrderRefundsStatus.error,
        },
      },
    }) as RootState
}

export function setOrderCreditRefundsLoading(state: RootState) {
  return () =>
    ({
      ...state,
      refunds: {
        ...state.refunds,
        orderCreditRefunds: {
          ...state.refunds.orderCreditRefunds,
          status: OrderRefundsStatus.loading,
        },
      },
    }) as RootState
}

export function setOrderCreditRefundsError(state: RootState) {
  return () =>
    ({
      ...state,
      refunds: {
        ...state.refunds,
        orderCreditRefunds: {
          ...state.refunds.orderCreditRefunds,
          status: OrderRefundsStatus.error,
        },
      },
    }) as RootState
}

export function setOrderCreditRefundsLoaded(state: RootState) {
  return ({ data }: { data: ApiOrderCreditRefund[] }) =>
    ({
      ...state,
      refunds: {
        ...state.refunds,
        orderCreditRefunds: {
          ...state.refunds.orderCreditRefunds,
          data,
          status: OrderRefundsStatus.loaded,
        },
      },
    }) as RootState
}
