import moment from 'moment'
import { map, filter, identity, pipe } from 'ramda'
import { Cell, TableRow } from '~/app/components/Table'
import ProductTypeIcon from '~/app/components/Icons/ProductTypeIcon'
import FaultyOrderIcon from '~/app/assets/svg/FaultyOrderIcon'
import { AddOnType, ProductType } from '~/app/common/types'
import { OrderProductCountReturnType } from './helpers'
import { getChargeDetails } from './utils'
import { ProductsCount, Count, ProductsCounts, IconWrapper } from './styled'
import type { Order } from './types'
import { sumBy, groupBy, orderBy } from 'lodash'
import ReportIssueAndRefundDialog from './ReportIssueAndRefundDialog'
import { useMappedState } from '~/app/hooks/useReduxStore'
import { DiscountType } from './types'
import CancelIcon from '~/app/assets/svg/CancelIcon'
import { useState, useEffect } from 'react'
import ConfirmationModal from '~/app/components/Modal/ConfirmationModal'
import user$ from '~/app/store/user'
import faultyOrderReasons$ from '~/app/store/faultyOrderReasons'
import { selectGiftProducts } from '~/app/store/products/selectors'

interface Props {
  index: number
  onClick: React.Dispatch<React.SetStateAction<number | null>>
  order: Order
  productCount: OrderProductCountReturnType
  userId: number
  isFirst: boolean
}

export default function UserOrder(props: Props) {
  const self = useController(props)

  if (!self.productsByIdOrSku) {
    return null
  }

  return (
    <>
      <TableRow
        isClickable
        onClick={() => props.onClick(props.order.id)}
        danger={Boolean(props.order.deliveryIssues?.length || props.order.charge.refunds?.length)}
        informative={props.order.type === 'manual'}
      >
        <Cell>
          Order id: #{props.order.id} <br /> <br />
          Shipment id: {self.shipmentId}
        </Cell>
        <Cell>{self.orderDate}</Cell>
        <Cell>
          {props.order.trackingUrl ? (
            <a href={props.order.trackingUrl} target="_blank">
              {self.orderStatus}
            </a>
          ) : (
            self.orderStatus
          )}
        </Cell>
        <Cell>{self.couponCode}</Cell>

        <Cell>
          <ProductsCounts>
            {self.productTypeCounts.map((product) => (
              <ProductsCount key={product.type}>
                <Count>{product.count}</Count>
                <ProductTypeIcon type={product.type} />
              </ProductsCount>
            ))}
          </ProductsCounts>
        </Cell>
        <Cell>{props.order.charge && `$${self.charge.orderValueCentsWithDiscount}`}</Cell>

        <Cell>
          {self.allowReporting && (
            <IconWrapper
              // @ts-ignore
              isRed={Boolean(
                props.order.deliveryIssues?.length || props.order.charge.refunds?.length,
              )}
              onClick={self.handleSetFaultyOrder}
            >
              <FaultyOrderIcon />
              {props.order.deliveryIssues?.length || props.order.charge.refunds?.length
                ? 'Report Another Issue'
                : 'Report Issue'}
            </IconWrapper>
          )}
          {!self.hideCancelOrderButton && (
            <IconWrapper onClick={self.openCancelOrderModal}>
              <CancelIcon width={20} height={20} />
              Cancel
            </IconWrapper>
          )}
        </Cell>
      </TableRow>
      <ConfirmationModal
        isOpen={self.showConfirmationModal}
        handleCancel={self.closeCancelOrderModal}
        handleConfirm={self.handleConfirmCancelOrder}
        title="Cancel Order"
        text="Are you sure you want to cancel this order?"
        confirmText="Yes"
        cancelText="No"
      />
      {self.useOrderIncidentFormController.showOrderIncidentModal && props.order && (
        <ReportIssueAndRefundDialog
          isOpen
          onConfirm={self.useOrderIncidentFormController.handleFaultyOrderConfirm}
          faultyOrder={props.order}
          setShowOrderIncidentModal={self.useOrderIncidentFormController.setShowOrderIncidentModal}
          faultyOrderReasons={self.useOrderIncidentFormController.faultyOrderReasons}
          giftProducts={self.useOrderIncidentFormController.giftProducts}
          userId={props.userId}
          orderTotal={self.charge.orderValueCentsWithDiscount}
        />
      )}
    </>
  )
}

function useController(props: Props) {
  const { productsByIdOrSku } = useMappedState((state) => ({
    productsByIdOrSku: state.products.dataByIdOrSku || {},
  }))
  const couponCode = getCouponCode(props)
  const charge = getChargeDetails(props.order, productsByIdOrSku)
  const productsByType = groupBy(props.order.products, 'type')
  const anchorProductCount = Object.entries(productsByType).map(([type, products]) => {
    return {
      type,
      count: sumBy(products, 'count'),
    } as { type: AddOnType | ProductType; count: number }
  })
  const addOnsCount = props.order.addOns.map((addOn) => {
    return { type: addOn.type, count: sumBy(addOn.products, 'count') }
  })
  const orderStatus =
    props.order.type === 'manual' ? `Manual order - ${props.order.status}` : props.order.status
  const orderDate = moment(props.order.processedAt).format('DD MMM YYYY')
  const addOnTypeToCountMap = new Map<AddOnType, number>(
    props.order.addOns.map((addOn) => [addOn.type, sumBy(addOn.products, 'count')]),
  )
  const productTypeCounts = orderBy([...anchorProductCount, ...addOnsCount], 'count', 'desc')
  const shipmentId = props.order.shipment ? `#${props.order.shipment.id}` : ''
  const allowReporting =
    props.order.status !== 'pending' &&
    moment(props.order.editableTo).isAfter(moment().subtract(3, 'months'))

  const {
    showConfirmationModal,
    openCancelOrderModal,
    closeCancelOrderModal,
    handleConfirmCancelOrder,
    hideCancelOrderButton,
  } = useCancelOrderController(props)

  const useOrderIncidentFormController = useOrderIncidentForm(props)

  const handleSetFaultyOrder = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    useOrderIncidentFormController.setShowOrderIncidentModal(true)
  }

  return {
    addOnTypeToCountMap,
    productTypeCounts,
    charge,
    couponCode,
    handleSetFaultyOrder,
    orderDate,
    productsByIdOrSku,
    shipmentId,
    allowReporting,
    showConfirmationModal,
    openCancelOrderModal,
    closeCancelOrderModal,
    handleConfirmCancelOrder,
    hideCancelOrderButton,
    orderStatus,
    useOrderIncidentFormController,
  }
}

/**
 * Finds the used coupon in the order.
 * When order has template level code, returns it, otherwise returns user level code.
 * @param props - Properties
 */
function getCouponCode({ order: { templateId, charge } }: Props) {
  const { receiptJson } = charge
  const findTemplateLevelCoupon = () =>
    receiptJson?.discounts.find(
      (discount) => discount.templateId === templateId && discount.type === DiscountType.coupon,
    )
  const findUserLevelCode = () =>
    receiptJson?.discounts.find(
      (discount) => !discount.templateId && discount.type === DiscountType.coupon,
    )
  return (findTemplateLevelCoupon() || findUserLevelCode())?.discountCode
}

function useCancelOrderController(props: Props) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  // EditableTo comes with a timestamp that is dropped so we need to manually
  // add 23 hours to match the BE. This is a temporary solution since it's possible
  // that the onboarding charge experiment will end and we will not need this anymore.
  // If for some reason we need to keep this, we should store the original editableTo
  // timestamp in the order object.
  const editableToWithTime = moment.utc(props.order.editableTo).add(23, 'hours')
  const now = moment()
  const isOneHourPassedAfterOrderEditableTo = now.isAfter(editableToWithTime)

  const hideCancelOrderButton =
    props.order.canceledAt ||
    isOneHourPassedAfterOrderEditableTo ||
    props.order.shipment ||
    !props.isFirst

  const openCancelOrderModal = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    setShowConfirmationModal(true)
  }

  const closeCancelOrderModal = () => {
    setShowConfirmationModal(false)
  }

  const handleConfirmCancelOrder = async () => {
    await user$.call.cancelOrder({ userId: props.userId, orderId: props.order.id })
    closeCancelOrderModal()
  }

  return {
    showConfirmationModal,
    openCancelOrderModal,
    closeCancelOrderModal,
    handleConfirmCancelOrder,
    hideCancelOrderButton,
  }
}

function useOrderIncidentForm(props: Props) {
  const { faultyOrderReasons, giftProducts } = useMappedState((state: any) => ({
    faultyOrderReasons: faultyOrderReasons$.getReasons(state),
    giftProducts: selectGiftProducts(state),
  }))
  const [showOrderIncidentModal, setShowOrderIncidentModal] = useState(false)

  useEffect(() => {
    if (!faultyOrderReasons?.length) {
      faultyOrderReasons$.call.fetchFaultyOrderReasons()
    }
  }, [props.order])

  const handleFaultyOrderConfirm = (data) => {
    const parseIds = pipe(
      filter(identity),
      map((id) => Number(id)),
    )
    const formattedData: Omit<OrderDeliveryIssues, 'reporter'> = {
      issueIds: parseIds(data.issueIds) as number[],
      note: data.note,
      photosProvided: Boolean(data.photosProvided),
      resent: Boolean(data.resent),
      products: data.products,
      intercomLink: data.intercomLink,
      photos: data.photos,
    }

    if (data.extraProductIds && data.resent) {
      formattedData.extraProductIds = [Number(data.extraProductIds)]
    }

    user$.call.setFaultyOrder({
      orderId: props.order.id,
      userId: props.userId,
      resend: formattedData.resent,
      ...formattedData,
    })
  }

  return {
    faultyOrderReasons,
    giftProducts,
    showOrderIncidentModal,
    setShowOrderIncidentModal,
    handleFaultyOrderConfirm,
  }
}
