import { required } from '~/app/common/validators'
import Select from '~/app/components/InputTypes/Select'
import Checkbox from '~/app/components/InputTypes/Checkbox'
import InputTextArea from '~/app/components/InputTypes/Textarea'
import Switch from '~/app/components/InputTypes/Switch'
import SmallText from '~/app/components/InputTypes/SmallText'

const formDefinition = [
  {
    hasEmptyOption: true,
    label: 'Order issue *',
    name: 'primaryIssue',
    type: Select,
    labelMargins: '1rem 0 0.5rem 0',
    validators: [required],
  },
  {
    hasEmptyOption: true,
    label: 'Order issue 2',
    name: 'secondaryIssue',
    labelMargins: '0 0 0.5rem 0',
    type: Select,
  },
  {
    label: 'Link to Customer Conversation',
    name: 'intercomLink',
    labelMargins: '0 0 0.5rem 0',
    type: SmallText,
  },
  {
    label: 'Note *',
    name: 'note',
    labelMargins: '0 0 0.5rem 0',
    type: InputTextArea,
    validators: [required],
  },
  {
    label: 'User Provided Photos?',
    name: 'photosProvided',
    textIfFalse: 'No',
    textIfTrue: 'Yes',
    type: Switch,
    labelMargins: '0 0 0.5rem 0',
    value: false,
  },
  {
    label: 'Would you like to resend the order?',
    name: 'resend',
    text: 'Yes',
    labelMargins: '0 0 0.5rem 0',
    type: Checkbox,
  },
  {
    hasEmptyOption: true,
    label: 'Free Gift',
    name: 'extraProductIds',
    labelMargins: '0 0 0.5rem 0',
    type: Select,
  },
]
export default formDefinition
