import { useState, useEffect, useCallback } from 'react'
import creditReasons$ from '~/app/store/creditReasons'
import { selectUser } from '~/app/store/app/selectors'
import useForm from '~/app/hooks/useForm'
import { useMappedState } from '~/app/hooks/useReduxStore'
import Button from '~/app/components/Button/Button'
import { ResponsiveRow, CenteredRow, Half, ThreeFourth } from '~/app/components/FormGrid'
import alert$ from '~/app/store/alert'

import formDefinition from './formDefinition'
import {
  ButtonsRow,
  MainTitleText,
  MinusButton,
  PlusButton,
  Product,
  Products,
  RefundSummary,
  TitleText,
  OrderTotalText,
  OrderTotalSubText,
} from '../styled'
import Loader from '~/app/components/Loader'
import { RootState } from '~/app/store/user/refunds/types'
import { Order } from '../../types'
import { convertCentsToDollars } from '~/app/utils/convertCentsToDollars'
import user$ from '~/app/store/user'

type Props = {
  order: Order
  handleConfirm: (data: {
    amount: number
    note: string
    notify: boolean
    mainReasonId: number
    subReasonId: number
    authorId: number
  }) => void
  isLoading: boolean
  orderTotal: number
}

const CENTS_MULTIPLIER = 100
const MAX_CREDITS_CENTS = 300 * CENTS_MULTIPLIER

export default function CreditRefundForm(props: Props) {
  const self = useController(props)

  return (
    <div>
      <MainTitleText>Credit Order</MainTitleText>
      <OrderTotalText>Order Total: ${props.orderTotal}*</OrderTotalText>
      <OrderTotalSubText extraBottomMargin>
        *may not be accurate if a credit or promo was used
      </OrderTotalSubText>
      <CenteredRow>
        <ThreeFourth>
          <TitleText>Products to refund</TitleText>
          <Products>
            {self.productsToRefund.map((product, index) => (
              <Product key={product.sku}>
                <span>
                  {self.productsToRefund[index].count}
                  {`/${product.maxCount} `}
                  {self.productsByIdOrSku?.[product.sku]?.name ?? product.sku}
                </span>

                <ButtonsRow>
                  <MinusButton
                    disabled={self.form.formState.isCustomCredit.value}
                    onClick={() => self.decreaseProductCount(index)}
                  >
                    -
                  </MinusButton>
                  <PlusButton
                    disabled={self.form.formState.isCustomCredit.value}
                    onClick={() => self.increaseProductCount(index)}
                  >
                    +
                  </PlusButton>
                </ButtonsRow>
              </Product>
            ))}
          </Products>
          {self.orderRefundsSummary && (
            <RefundSummary>
              <TitleText>Refund summary</TitleText>
              <TitleText>
                {self.orderRefundsSummary.error
                  ? self.orderRefundsSummary.error
                  : `$${convertCentsToDollars(self.orderRefundsSummary.amount)}`}
              </TitleText>
            </RefundSummary>
          )}
          <Button secondary small onClick={self.selectAllProducts} disabled={props.isLoading}>
            Select All
          </Button>
        </ThreeFourth>
      </CenteredRow>
      <CenteredRow>
        {self.form.renderField('isCustomCredit', {
          value: self.form.formState.isCustomCredit.value,
        })}
      </CenteredRow>
      {self.form.formState.isCustomCredit.value && (
        <CenteredRow>
          <Half>
            {self.form.renderField('amount', {
              value: self.form.formState.amount.value,
            })}
          </Half>
        </CenteredRow>
      )}
      <CenteredRow>
        <ThreeFourth>
          <ResponsiveRow>
            <Half>{self.form.renderField('note')}</Half>
            <Half>{self.form.renderField('notify')}</Half>
          </ResponsiveRow>
        </ThreeFourth>
      </CenteredRow>
      <CenteredRow>
        <ThreeFourth>
          <ResponsiveRow>
            <Half>
              {self.form.renderField('mainCreditsReason', {
                onChange: self.handleMainCreditReasonChange,
                options: self.creditReasons.map(
                  (reason: { reasonValue: string; reasonId: number }) => ({
                    label: reason.reasonValue,
                    value: reason.reasonId,
                  }),
                ),
                value: self.form.formState.mainCreditsReason.value,
              })}
            </Half>
            {self.creditSubReasons.length > 0 && (
              <Half>
                {self.form.renderField('subCreditsReason', {
                  options: self.creditSubReasons.map((sub: { value: string; id: number }) => ({
                    label: sub.value,
                    value: sub.id,
                  })),
                  value: self.form.formState.subCreditsReason.value,
                })}
              </Half>
            )}
          </ResponsiveRow>
        </ThreeFourth>
      </CenteredRow>
      <CenteredRow>
        <Button
          primary
          large
          onClick={self.handleConfirm}
          disabled={
            props.isLoading ||
            (self.form.formState.isCustomCredit.value
              ? !self.form.isValid
              : !self.isCalculatorFormValid)
          }
        >
          {props.isLoading ? <Loader /> : 'Submit'}
        </Button>
      </CenteredRow>
    </div>
  )
}

function useController(props: Props) {
  const form = useForm({
    fieldDefinitions: formDefinition,
  })
  const [creditSubReasons, setCreditSubReasons] = useState<{ value: string; id: number }[]>([])
  const { adminUser, creditReasons, reasonsStatus } = useMappedState((state) => ({
    adminUser: selectUser(state),
    creditReasons: creditReasons$.getReasons(state),
    reasonsStatus: creditReasons$.getStatus(state),
  }))

  const { productsByIdOrSku, orderRefundsSummary, orderRefunds } = useMappedState((state) => ({
    productsByIdOrSku: state.products.dataByIdOrSku || {},
    orderRefundsSummary: state.user.refunds.orderRefunds.summary,
    orderRefunds: state.user.refunds.orderRefunds as RootState['refunds']['orderRefunds'],
  }))

  const {
    productsToRefund,
    setProductsToRefund,
    decreaseProductCount,
    increaseProductCount,
    selectAllProducts,
    resetProductsToRefund,
  } = useProductsToRefundController(
    props,
    productsByIdOrSku,
    orderRefunds,
    form.formState.isCustomCredit.value,
  )

  const isCalculatorFormValid =
    !form.formState.isCustomCredit.value &&
    orderRefundsSummary.amount > 0 &&
    form.formState.note.value &&
    form.formState.mainCreditsReason.value &&
    form.formState.subCreditsReason.value

  useEffect(() => {
    if (reasonsStatus === 'pending') {
      creditReasons$.call.fetchCreditReasons()
    }
  }, [])

  const handleConfirm = () => {
    const amountCents = Math.round(form.formState.amount.value * CENTS_MULTIPLIER)

    if (amountCents > MAX_CREDITS_CENTS) {
      alert$.call.setNotification(
        `Max amount of credits to add is $${MAX_CREDITS_CENTS / CENTS_MULTIPLIER}`,
      )
      return
    }

    props.handleConfirm({
      amount: form.formState.isCustomCredit.value ? amountCents : orderRefundsSummary.amount,
      authorId: adminUser.id,
      mainReasonId: form.formState.mainCreditsReason.value,
      note: form.formState.note.value,
      notify: form.formState.notify.value,
      subReasonId: form.formState.subCreditsReason.value,
    })
  }

  const handleMainCreditReasonChange = (value: string) => {
    setCreditSubReasons(() => {
      const reason = creditReasons.find(
        (x: { reasonId: number }) => x.reasonId.toString() === value,
      )
      return reason.subReasons
    })
    form.onFieldChange('mainCreditsReason', value)
  }

  return {
    creditReasons,
    creditSubReasons,
    form,
    handleConfirm,
    handleMainCreditReasonChange,
    reasonsStatus,
    productsToRefund,
    setProductsToRefund,
    decreaseProductCount,
    increaseProductCount,
    selectAllProducts,
    resetProductsToRefund,
    orderRefundsSummary,
    orderRefunds,
    productsByIdOrSku,
    isCalculatorFormValid,
  }
}

function useProductsToRefundController(
  props: Props,
  productsByIdOrSku: any,
  orderRefunds: RootState['refunds']['orderRefunds'],
  isCustomCredit: boolean,
) {
  const getInitialProductsToRefund = useCallback(() => {
    const refundedProductCounts = (orderRefunds.data ?? []).reduce(
      (acc, refund) => {
        refund.products?.forEach((product: { id: number; count: number }) => {
          acc[product.id] = (acc[product.id] || 0) + product.count
        })
        return acc
      },
      {} as Record<number, number>,
    )

    const products =
      props.order?.charge.receiptJson?.orders.find((order) => order.id === props.order.id)
        ?.itemizedList ?? []

    return products
      .filter((product) => {
        const refundedCount = refundedProductCounts[product.id] || 0
        return refundedCount < product.quantity
      })
      .map((product) => {
        const refundedCount = refundedProductCounts[product.id] || 0
        const remainingCount = product.quantity - refundedCount
        return {
          id: product.id,
          sku: productsByIdOrSku[product.id]?.sku ?? product.id,
          count: 0,
          maxCount: remainingCount,
        }
      })
  }, [props.order, orderRefunds, productsByIdOrSku])

  const [productsToRefund, setProductsToRefund] = useState(getInitialProductsToRefund())

  const decreaseProductCount = (index: number) => {
    if (isCustomCredit) {
      return
    }

    const updatedProducts = [...productsToRefund]
    if (updatedProducts[index].count === 0) {
      return
    }

    const product = updatedProducts[index]
    updatedProducts[index] = { ...product, count: product.count - 1 }
    setProductsToRefund(updatedProducts)
  }

  const increaseProductCount = (index: number) => {
    if (isCustomCredit) {
      return
    }

    const updatedProducts = [...productsToRefund]
    if (updatedProducts[index].count === updatedProducts[index].maxCount) {
      return
    }

    const product = updatedProducts[index]
    updatedProducts[index] = { ...product, count: product.count + 1 }
    setProductsToRefund(updatedProducts)
  }

  const selectAllProducts = () => {
    if (isCustomCredit) {
      return
    }

    setProductsToRefund(
      getInitialProductsToRefund().map((product) => ({ ...product, count: product.maxCount })),
    )
  }

  const resetProductsToRefund = () => {
    setProductsToRefund(getInitialProductsToRefund())
  }

  useEffect(() => {
    user$.call.fetchOrderRefundSummary({
      orderId: props.order.id,
      products: productsToRefund
        .filter((product) => product.count > 0)
        .map((product) => ({
          id: product.id,
          count: product.count,
        })),
      isCredit: true,
    })
  }, [props.order.id, productsToRefund])

  useEffect(() => {
    if (isCustomCredit) {
      resetProductsToRefund()
    }
  }, [isCustomCredit])

  return {
    productsToRefund,
    setProductsToRefund,
    decreaseProductCount,
    increaseProductCount,
    selectAllProducts,
    resetProductsToRefund,
  }
}
