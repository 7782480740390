import type { ApiOrderRefund, ApiOrderCreditRefund } from 'app/common/api/types'
import type * as reducers from './reducers'

export type OrderRefunds = ApiOrderRefund[]

export type ChargeCreditRefunds = ApiOrderCreditRefund[]

export enum OrderRefundsStatus {
  pending = 'pending',
  loading = 'loading',
  loaded = 'loaded',
  error = 'error',
}

export interface OrderRefundsState {
  orderRefunds: {
    data: OrderRefunds
    status: OrderRefundsStatus
  }
  orderCreditRefunds: {
    data: ChargeCreditRefunds
    status: OrderRefundsStatus
  }
}

export type RootState = object & {
  refunds: OrderRefundsState
}

export type OrderRefundsReducers = {
  [key in keyof typeof reducers]: ReturnType<(typeof reducers)[key]>
}
