import api from '~/app/common/api'
import alert$ from '~/app/store/alert'
import { convertCentsToDollars } from '~/app/utils/convertCentsToDollars'
import { OrderRefundsReducers } from '~/app/store/user/refunds/types'

export function fetchOrderRefunds(store: any, self: object & OrderRefundsReducers) {
  return async (orderId: number): Promise<void> => {
    store.dispatch(self.setOrderRefundsLoading())

    try {
      const response = await api.fetchOrderRefunds(orderId)
      await store.dispatch(self.setOrderRefundsLoaded({ data: response }))
    } catch (error) {
      store.dispatch(self.setOrderRefundsError())
      alert$.call.setNotification(String(error))
    }
  }
}

export function fetchOrderRefundSummary(store: any, self: object & OrderRefundsReducers) {
  return async ({
    orderId,
    products,
    isCredit,
  }: {
    orderId: number
    products: { id: number; count: number }[]
    isCredit?: boolean
  }): Promise<void> => {
    try {
      const response = await api.fetchOrderRefundSummary(orderId, products, isCredit)
      await store.dispatch(self.setOrderRefundsSummaryLoaded({ data: response }))
    } catch (error) {
      store.dispatch(self.setOrderRefundsError())
      alert$.call.setNotification(String(error))
    }
  }
}

export function fetchChargeCreditRefunds(store: any, self: object & OrderRefundsReducers) {
  return async ({ userId, chargeId }: { userId: number; chargeId: number }): Promise<void> => {
    try {
      store.dispatch(self.setOrderCreditRefundsLoading())
      const response = await api.fetchOrderCreditRefunds(userId, chargeId)
      await store.dispatch(self.setOrderCreditRefundsLoaded({ data: response }))
    } catch (error) {
      store.dispatch(self.setOrderCreditRefundsError())
      alert$.call.setNotification(String(error))
    }
  }
}

export function createOrderRefund(store: any, self: object & OrderRefundsReducers) {
  return async (payload: {
    products: { id: number; count: number }[]
    orderId: number
    chargeId: number
    userId: number
    customRefundReason?: string
    customRefundAmount?: number
  }): Promise<void> => {
    const { products, orderId, customRefundReason, customRefundAmount, userId, chargeId } = payload
    try {
      store.dispatch(self.setOrderRefundsLoading())
      const refundResponse = await api.refundOrder(orderId, {
        products,
        customRefundReason,
        customRefundAmount,
      })
      alert$.call.setNotification(
        `${refundResponse.message}. $${convertCentsToDollars(refundResponse.amount)} refunded`,
      )
    } catch (error) {
      alert$.call.setNotification(String(error))
      store.dispatch(self.setOrderRefundsError())
    } finally {
      const [orderRefunds, orderCreditRefunds] = await Promise.all([
        api.fetchOrderRefunds(orderId),
        api.fetchOrderCreditRefunds(userId, chargeId),
      ])
      await store.dispatch(self.setOrderRefundsLoaded({ data: orderRefunds }))
      await store.dispatch(self.setOrderCreditRefundsLoaded({ data: orderCreditRefunds }))
    }
  }
}
