import moment from 'moment'
import { get } from 'lodash'
import { Heading, Info } from '../styled'
import { OrderDeliveryIssues } from '../../types'
import OrderIssueForm from './OrderIssueForm'

export default function PreviousOrderIssuesTable({
  data,
  faultyOrderReasons,
  giftProducts,
}: {
  data: OrderDeliveryIssues[]
  faultyOrderReasons: { name: string; options: { label: string; value: number }[] }[]
  giftProducts: any[]
}) {
  return (
    <>
      {data.map((issue: OrderDeliveryIssues, index: number) => (
        <div key={`${issue.createdAt}`}>
          <Heading>Issue #{index + 1}</Heading>
          <Info>Reported on: {moment(issue.createdAt).format('DD MMM YYYY, HH:mm')}</Info>
          {issue.reporter && <Info>Reported by: {issue.reporter.email}</Info>}
          <OrderIssueForm
            onSubmit={() => {}}
            isFaulty
            issueIds={issue.issueIds}
            resend={issue.resent}
            note={issue.note}
            photosProvided={issue.photosProvided}
            faultyOrderReasons={faultyOrderReasons}
            giftProducts={giftProducts}
            extraProduct={get(issue, 'extraProductIds[0]', false)}
            products={issue.products}
            intercomLink={issue.intercomLink}
            photos={issue.photos}
          />
        </div>
      ))}
    </>
  )
}
