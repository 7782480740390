import styled from 'styled-components'
import media from '~/app/utils/mediaQueries'

export const Wrapper = styled.div`
  overflow: hidden;
  border-radius: inherit;
  text-align: center;
`

export const Content = styled.div`
  font-size: 1rem;
  color: ${(props) => props.theme.midGray};
  padding: 0 1rem 2.5rem;
  height: 100%;
  max-height: inherit;
  > p {
    margin-bottom: 1.2em;
  }
  > h2 {
    color: ${(props) => props.theme.darkGray};
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
  }

  ${media.lg} {
    width: 100rem;
  }
`

export const Header = styled.header`
  padding: 2.5rem 2.5rem 0;
  position: relative;
  border-radius: inherit;
`

export const Heading = styled.h2<{ noTopMargin?: boolean }>`
  font-size: 1.875rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: ${({ noTopMargin }) => (noTopMargin ? '0' : '70px')};
  margin-bottom: 0.8rem;
`

export const SubTitle = styled.h6`
  font-size: 11px;
  margin-top: 0;
  color: ${(props) => props.theme.color.darkGray};
`

export const Products = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0;
  font-size: 1.5rem;
`

export const Product = styled.li`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 0.8rem;

  ${media.lg} {
    flex-direction: row;
    justify-content: flex-start;
  }
`

export const ButtonsRow = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const PlusButton = styled.button`
  border-radius: 50%;
  width: 21px;
  height: 21px;
  background-color: ${(props) => props.theme.color.green};
  border: none;
  color: ${(props) => props.theme.color.white};
`

export const MinusButton = styled(PlusButton)`
  background-color: ${(props) => props.theme.color.red};
`

export const Info = styled.p`
  font-size: 1.5rem;
`

export const ImageUploadSection = styled.div`
  margin-bottom: 5rem;
`

export const ImagesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  border: 0.1rem solid #ccc;
  padding: 1rem;
  margin-bottom: 2rem;
  justify-content: center;
  border-radius: 0.5rem;
`

export const ImageWrapper = styled.a`
  flex: 1 1 calc(30% - 1rem);
  max-width: calc(30% - 1rem);
  box-sizing: border-box;
  border: 0.1rem solid #ccc;
  overflow: hidden;
  background: #f0f0f0;

  img {
    width: 100%;
    height: auto;
  }
`

export const LotCodeInputWrapper = styled.div`
  width: 20rem;

  ${media.lg} {
    margin-left: auto;
  }
`

export const RefundSummary = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 4rem;
`

export const MainTitleText = styled.h2`
  font-size: 2.4rem;
  font-weight: 700;
  text-align: center;
  color: ${(props) => props.theme.color.darkGray};
  text-transform: uppercase;
`

export const OrderTotalText = styled.p`
  font-size: 1.4rem;
  font-weight: 800;
  text-align: center;
  color: ${(props) => props.theme.color.darkGray};
  text-transform: uppercase;
`

export const OrderTotalSubText = styled.p<{ extraBottomMargin?: boolean }>`
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  color: ${(props) => props.theme.color.darkGray};
  text-transform: uppercase;
  ${(props) => (props.extraBottomMargin ? 'margin-bottom: 4rem;' : '')}
`

export const TitleText = styled.h3`
  font-size: 2rem;
  font-weight: 600;
  text-align: left;
`

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 45rem;
`

export const CustomRefundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
`

export const DialogHeader = styled.header`
  padding: 2.5rem 2.5rem 0;
  position: relative;
  border-radius: inherit;
`

export const TableWrapper = styled.div`
  max-width: 72.5rem;
  margin: 0 auto;
  margin-top: 2rem;
`

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  flex-direction: column;
`

export const SwitchTitle = styled.label`
  margin: 2rem 0 1.2rem;
  color: ${(props) => props.theme.color.darkGray};
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: 0.05rem;
  font-weight: 700;
  text-align: center;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  width: fit-content;
  margin: 2rem auto 0;
`

export const IconButton = styled.button<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${(props) => props.theme.color.darkGray};
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2rem;
  gap: 0.5rem;
  background: none;
  border: none;
  background-color: ${(props) => (props.isActive ? props.theme.color.lightGray : 'transparent')};
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transition: background-color 0.3s ease-in-out;
  width: 7rem;

  svg {
    width: 2rem;
    fill: none;
    height: 2rem;
  }

  :hover {
    background-color: rgb(228, 232, 238);
  }
`

export const TableSubHeading = styled.h4`
  text-align: left;
  margin-bottom: 0.5rem;
`
