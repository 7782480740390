import moment from 'moment'
import { Cell, Header, ListTable, TableBody, TableHead, TableRow } from '~/app/components/Table'
import { convertCentsToDollars } from '~/app/utils/convertCentsToDollars'
import { ApiOrderRefund } from '~/app/common/api/types'
import { TableSubHeading, TableWrapper } from '../styled'

export default function PreviousRefunds({
  orderRefunds,
  productsByIdOrSku,
}: {
  orderRefunds: ApiOrderRefund[]
  productsByIdOrSku: any
}) {
  if (orderRefunds.length === 0) {
    return null
  }

  return (
    <TableWrapper>
      <TableSubHeading>Previous Refunds</TableSubHeading>
      <ListTable>
        <TableHead>
          <TableRow>
            <Header>Order ID</Header>
            <Header>Amount</Header>
            <Header>Date</Header>
            <Header>Products</Header>
            <Header>Author</Header>
            <Header>Custom Reason</Header>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderRefunds.map((refund: ApiOrderRefund) => (
            <TableRow key={String(refund.createdAt)}>
              <Cell>{refund.metadata?.orderId}</Cell>
              <Cell>${convertCentsToDollars(refund.amount)}</Cell>
              <Cell>{moment(refund.createdAt).format('MM/DD/YYYY')}</Cell>
              <Cell>
                {refund.products?.map((product: { id: number; count: number }) => (
                  <div key={product.id}>
                    {productsByIdOrSku[product.id]?.name ?? product.id} x {product.count}
                  </div>
                ))}
              </Cell>
              <Cell>{refund.user?.email ?? 'N/A'}</Cell>
              <Cell>{refund.customRefundReason}</Cell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
    </TableWrapper>
  )
}
