import moment from 'moment'
import { Cell, Header, ListTable, TableBody, TableHead, TableRow } from '~/app/components/Table'
import { convertCentsToDollars } from '~/app/utils/convertCentsToDollars'
import { ApiOrderCreditRefund } from '~/app/common/api/types'
import { TableSubHeading, TableWrapper } from '../styled'

export default function PreviousRefunds({
  orderCreditRefunds,
}: {
  orderCreditRefunds: ApiOrderCreditRefund[]
}) {
  if (orderCreditRefunds.length === 0) {
    return null
  }

  return (
    <TableWrapper>
      <TableSubHeading>Previous Credit Refunds</TableSubHeading>
      <ListTable>
        <TableHead>
          <TableRow>
            <Header>Created At</Header>
            <Header>Amount</Header>
            <Header>Note</Header>
            <Header>Reason</Header>
            <Header>Sub Reason</Header>
            <Header>Author</Header>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderCreditRefunds.map((refund: ApiOrderCreditRefund) => (
            <TableRow key={String(refund.createdAt)}>
              <Cell>{moment(refund.createdAt).format('MM/DD/YYYY')}</Cell>
              <Cell>${convertCentsToDollars(refund.amount)}</Cell>
              <Cell>{refund.note}</Cell>
              <Cell>{refund.mainReason}</Cell>
              <Cell>{refund.subReason}</Cell>
              <Cell>{refund.authorEmail}</Cell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
    </TableWrapper>
  )
}
